<template>
  <base-layout page-title="Diagnostics">
    <template v-slot:main-content>
      <div id="container" class="ion-padding">

        <ion-segment @ionChange="segmentChanged($event)" color="primary" mode="ios" value="run">
          <ion-segment-button value="list">
            <ion-label>Download Reports</ion-label>
          </ion-segment-button>
          <ion-segment-button value="run">
            <ion-label>Schedule Diagnostic</ion-label>
          </ion-segment-button>
        </ion-segment>

        <div v-if="view=='run'">
          <ion-item>
            <ion-label position="stacked">Start Time (relative from now) -- <b>{{ startTimeOffset / 60 }} Minute(s)</b></ion-label>
            <ion-range min="60" max="3600" step="60" v-model="startTimeOffset" @ionChange="onChangeStartTimeOffset" color="secondary">
              <ion-label slot="start">Now</ion-label>
              <ion-label slot="end">60 Minutes</ion-label>
            </ion-range>
            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">Start Time (Actual)</ion-label>
                  <ion-input type="text" v-model="startTimeActual"></ion-input>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item>
                  <ion-label position="stacked">End Time (Estimated)</ion-label>
                  <ion-input readonly="true" v-model="endTimeActual" type="text"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-item>
          <ion-row>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">Sample Interval (ms)</ion-label>
                <ion-input min="100" type="number" @change="validateSampleInterval" v-model="sampleInterval"></ion-input>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item>
                <ion-label position="stacked">Sample Count</ion-label>
                <ion-input min="1" type="number" @change="validateSampleCount" v-model="sampleCount"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <!--
          <ion-item>
            <ion-label>
              <b>Measures</b>
            </ion-label>
          </ion-item>
          <div style="height:30px">&nbsp;</div>
          <ion-button v-if="!isSelectAll" @click="selectAll" fill="outline">Select All</ion-button>
          <ion-button v-if="isSelectAll" @click="deselectAll" fill="outline">Deselect All</ion-button>
          -->
          <ion-row>
            <ion-col>
              <ion-item><ion-label><b>Measures</b></ion-label>
              <ion-button v-if="!isSelectAll" @click="selectAll" fill="outline">Select All</ion-button>
              <ion-button v-if="isSelectAll" @click="deselectAll" fill="outline">Deselect All</ion-button>
              </ion-item>
            </ion-col>
            <ion-col>
              <ion-item><ion-button @click="runDiagnostics" fill="outline">Schedule Diagnostics</ion-button></ion-item>
            </ion-col>
          </ion-row>

          <ion-list>
            <ion-row>
              <ion-col size="6" v-for="measure in measures" :key="measure.value">
                <ion-item>
                  <ion-checkbox
                    slot="start"
                    @update:modelValue="measure.isChecked = $event"
                    :modelValue="measure.isChecked">
                  </ion-checkbox>
                  <!-- <input type="checkbox" name="{{ measure.label }}" v-model="measure.isChecked"> -->
                  <ion-label><label for="{{ measure.label }}">{{ measure.label }}</label></ion-label>
                </ion-item>
              </ion-col>
            </ion-row>
          </ion-list>
          <!-- <ion-button @click="runDiagnostics" fill="outline">Schedule Diagnostics</ion-button> -->
        </div>

        <div v-if="view=='list'">
          <ion-item>
            <ion-label>Select Date</ion-label>
            <input type="date" v-model="selectedDate" @change="changeSelectedDate">
          </ion-item>

          <ion-list>
              <ion-item v-for="report in reports" :key="report">
                <ion-label>
                  <h1>Started at {{ report.scheduledStart }}</h1>
                  <h2>Measure: {{ report.measure }}</h2>
                  <p>Sample Count: {{ report.sampleCount }}, Sample Interval: {{ report.sampleInterval }}</p>
                </ion-label>
                <ion-button @click="openDetails(report)" slot="end">Download CSV Report</ion-button>
              </ion-item>
          </ion-list>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script lang="ts">
import BaseLayout from '../BaseLayout.vue'
import { defineComponent } from 'vue'
import { vedaService } from '../../services/vedaService'
import { userService } from '../../services/userService'
import { IonRange, IonItem, IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue'
import { IonRow, IonCol, IonButton, IonList, IonInput, IonCheckbox } from '@ionic/vue'

export default defineComponent({
  name: 'Diagnostics',
  components: {
    BaseLayout,
    IonRange,
    IonItem,
    IonLabel,
    IonRow,
    IonCol,
    IonButton,
    IonList,
    IonSegmentButton,
    IonSegment,
    IonInput,
    IonCheckbox
  },
  data: ()=> {
    return {
      diagnostic: false,
      reports: [] as any[],
      selectedDate: new Date(),
      view: 'run',
      startTimeActual: '',
      endTimeActual: '',
      startTimeOffset: 60,
      sampleInterval: 1000,
      sampleCount: 20,
      isSelectAll: false,
      measures: [
        { label: 'Voltage (V)', value: 'V', isChecked: true },
        { label: 'Current (I)', value: 'I', isChecked: false },
        { label: 'Frequency (F)', value: 'F', isChecked: false },
        { label: 'Phase Angle (PA)', value: 'PA', isChecked: false },
        { label: 'Wideband Energy (E_WB)', value: 'E_WB', isChecked: false },
        { label: 'Fundamental Energy (E_FUN)', value: 'E_FUN', isChecked: false },
        { label: 'Reactive Energy (E_R)', value: 'E_R', isChecked: false },
        { label: 'Apparent Energy (E_APP)', value: 'E_APP', isChecked: false },
        { label: 'Wideband Power (P_WB)', value: 'P_WB', isChecked: false },
        { label: 'Fundamental Power (P)', value: 'P', isChecked: true },
        { label: 'Reactive Power (Q)', value: 'Q', isChecked: false },
        { label: 'Apparent Power (S)', value: 'S', isChecked: false },
      ]
      // "V","I","F","PA","E_WB","E_FUN","E_R","E_APP","P_WB","P","Q","S"
    }
  },
  created: async function() {
    // this.selectedDate = this.selectedDate || new Date().toISOString()
    const date = (new Date()).toISOString().split('T')[0].split('-').join('')
    const response = await vedaService.findDiagnosticsForDay(date)
    if (response.data) {
      this.reports = response.data.diagParams
    }
    setInterval(() => {
      const startEnd = new Date()
      startEnd.setSeconds(startEnd.getSeconds() + this.startTimeOffset)
      this.startTimeActual = this.formatDateString(startEnd)
      startEnd.setSeconds(startEnd.getSeconds() + (this.sampleCount * (this.sampleInterval / 1000)) + 60) // Add 60 seconds
      this.endTimeActual = this.formatDateString(startEnd)
    }, 1000)
  },
  methods: {
    async runDiagnostics() {
      this.diagnostic = true
      const measure = this.measures.filter(x=> x.isChecked).map(x => x.value)
      console.log(measure)
      let response: any
      try {
        response = await vedaService.scheduleDiagnostics(measure, this.startTimeActual, this.sampleInterval, this.sampleCount, this.endTimeActual)
        if (response?.data?.error === 'VEDA offline') {
          userService.openToast('VEDA is offline, unable to schedule Diagnostic', 'danger')
        } else {
          userService.openToast('Diagnostic scheduled successfully', 'success')
        }
      } catch (err) {
        userService.openToast('Failed to schedule Diagnostic', 'danger')
      }
    },
    async changeSelectedDate(e) {
      const date = e.target.value.split('-').join('')
      const response = await vedaService.findDiagnosticsForDay(date)
      if (response.data) {
        this.reports = response.data.diagParams
      }
    },
    formatDateString(date) {
      return date.toISOString().substr(0, 19).replace(/-/g, '').replace(/:/g, '') + 'Z'
    },
    async openDetails(report) {
      const response = await vedaService.downloadDiagnosticReport(report);
      console.log(report)
      this.download(report.target[0] + '_' + report.scheduledStart + '.csv', response.data.diagResults)
    },
    download(filename, text) {
      const element = document.createElement('a')
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
      element.setAttribute('download', filename)
      element.style.display = 'none'
      document.body.appendChild(element)
      element.click()
      document.body.removeChild(element)
    },
    async segmentChanged(event) {
      this.view = event.target.value
      if (event.target.value === 'list') {
        const responseListDiag = await vedaService.findDiagnosticsForDevice()
        if (responseListDiag.data) {
          this.reports = responseListDiag.data.diagParams
        }
      }
    },
    onChangeStartTimeOffset(e) {
      console.log(e.target.value)
    },
    selectAll() {
      this.measures.forEach(measure => {
        measure.isChecked = true
        this.isSelectAll = true
      })
    },
    deselectAll() {
      this.measures.forEach(measure => {
        measure.isChecked = false
        this.isSelectAll = false
      })
    },
    validateSampleInterval() {
      if (this.sampleInterval < 100) {
        userService.openToast('Sample Interval cannot be less than 100ms', 'warning')
        this.sampleInterval = 100
      }
    },
    validateSampleCount() {
      if (this.sampleCount < 1) {
        userService.openToast('Sample Count cannot be less than 1', 'warning')
        this.sampleCount = 1
      }
    }
  }
})
</script>
